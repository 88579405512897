import React from 'react'
import { isBrowser } from '@atomixdesign/rem-scaling'

export function getCurrentBanner(totalBanner) {
  const currentBannerValue = isBrowser() ? localStorage.getItem('CurrentBanner') : undefined
  
  let currentBanner = currentBannerValue && currentBannerValue.length>0? Number.parseInt(currentBannerValue) : 0
  
  if( currentBanner >= totalBanner){
    currentBanner = 1
  }else{
    currentBanner++
  }

  if (isBrowser()) {
    localStorage.setItem( 'CurrentBanner', currentBanner.toString() )
  }

  return currentBanner
}
 
export const HomeBanners: React.VFC<{ children: React.ReactNode[] }> = ( { children }) => {
  const  currentBanner = getCurrentBanner(children?children.length:0)

  const banner = children[currentBanner-1] ? children[currentBanner-1] : null
  
  return (<>{banner}</>)
}